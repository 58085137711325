<template>
  <mobile-screen :header="true" screen-class="gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="about-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_preferences-help-online',
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("preferences", "preferences", "preferences") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li
        v-for="category in categories.filter(
          (item) => !hiddenPreferenceCategories.includes(item.category)
        )"
        :key="category.id"
        class="clebex-item-section-item full-right-underline has-lead-icon"
      >
        <router-link
          :to="{
            name: 'r_preference-category',
            params: { categoryId: category.id },
          }"
        >
          <button class="clebex-item-content-wrapper">
            <span class="lead-icon">
              <button class="lead-icon-item">
                <icon
                  :icon="category.icon"
                  style="width: 100%; height: 20px;"
                />
              </button>
            </span>
            <span class="label">{{ category.name }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </button>
        </router-link>
      </li>
    </ul>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import { preferenceCategoriesIconsMap } from "@/services/preference-options";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "Preferences",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  data() {
    return {
      categories: [],
      preferenceCategoriesIconsMap: preferenceCategoriesIconsMap,
      hiddenPreferenceCategories: ["CALENDAR", "BOOKING"],
      helpSlug: "preferences",
    };
  },
  computed: {
    ...mapState("preferences", ["preferenceCategories"]),
  },
  methods: {
    setCategoryData() {
      this.preferenceCategories.forEach((category, i) => {
        this.categories.push(category);
        this.categories[i].icon =
          preferenceCategoriesIconsMap[category.category];
      });
    },
  },
  created() {
    this.setCategoryData();
  },
};
</script>
<style lang="scss" scoped>
.clebex-item-section-item {
  a {
    text-decoration: none;
  }
}
</style>
